import * as React from 'react'
import { Link } from "gatsby"
import Layout from '/src/components/layout'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faLanguage, faPlug, faQuestionCircle, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons'

const OrdUttryckPage = () => {
    return (
        <Layout pageTitle="Ord &amp; uttryck">

            <p class="intro">Saknar du något ord/uttryck? Skriv gärna till <Link to="/om/forslag">Laddify</Link> i sociala medier.</p>
            
            <h2>Laddning</h2>

            <h3>Laddstation</h3>
            <p>Det ställe där du via en eller flera laddpunkter kan ladda bilen. Som en bensinstation men för elbilar.</p>

            <h3>Laddstolpe/box, laddpunkt</h3>
            <p>På laddstationen finns en eller flera laddpunkter med ett eller flera uttag. Laddpunkten är den laddstolpe eller box du</p>
        
            <h3>Laddkontakt/uttag</h3>
            <p>På varje laddpunkt finns det ett eller flera uttag/laddkontakter som </p>
        
            <h3>Laddkort/tagg</h3>
            <p>För att starta laddning vid publika laddstationer behövs ett laddkort/tagg, app eller betalkort. </p>
        
            <h3>Normal-/destinationsladdning</h3>
            <p>Ibland även kallat långsam laddning och återfinns hemma eller som namnet antyder på destinationen, exempelvis arbetet, hotellet, nöjesparken, skidanläggningen, shoppingcentrat, mataffären eller parkeringshuset. Effekten brukar vara upp till 22 kW men beror på laddbox, tillgänglig effekt och bilens ombordladdare. Oftast kan effekten vara ganska låg och ändå fungera mycket väl eftersom bilen ofta står stilla flera timmar vid denna typ av laddning.</p>
        
            <h3>Snabbladdning</h3>
            <p>När det är bråttom att ladda snabbt, exempelvis på långresan, brukar snabbladdning användas för att korta ner laddtiden. Snabbladdning brukar vara minst 50 kW.</p>
        
            <h3>Supersnabbladdning</h3>
            <p>Som snabbladdning men ännu snabbare genom högre effekt, på Laddify 150 kW eller högre. </p>
        

            <h2>El</h2>
            
            <h3>Effekt</h3>
            <p>Med effekt menas den mängd elenergi som en elektrisk apparat förbrukar i varje ögonblick. Ju högre effekt en apparat har, desto mer energi (eller ström) går det åt för att driva apparaten.</p>
        
            <h3>kW</h3>
            <p>Enhet för att mäta effekt och rent praktiskt när vi pratar elbilsladdning laddtid, ju högre effekt ju snabbare laddas bilen.</p>
        
            <h3>kWh</h3>
            <p>Enhet för att hur mycket energi ("ström") en elektrisk apparat förbrukat. Att ladda 1 timme med 1 kW innebär 1 kWh. Med andra ord, ett batteri på 50 kWh laddas fullt på fem timmar med laddeffekt på 10 kW.</p>
        
            <h3>Ombordladdare</h3>
            <p>I elbilar finns en så kallad ombordladdare som vid destinationsladdning ("typ 2") omvandlar växelström till likström. Bilens ombordladdare kan exempelvis klara upp till 11 kW medan en laddbox kan klara 22 kW vilket gör att maximala laddeffekten blir upp till 11 kW.</p>
        
            <h3>DC, Likström</h3>
            <p>Batterier lagrar elen som likström.</p>
        
            <h3>AC, Växelström</h3>
            <p>I våra vanliga eluttag har vi växelström vilket behöver omvandlas till likström innan elbilen kan använda elen.</p>
        
            <h3>Fas</h3>
            <p>När vi pratar el och laddning så dyker ibland exempelvis 1 eller 3 fas upp vilket kan vara bra att känna till eftersom det påverkar laddtiden. Förutsatt att bilen klarar av 3 faser är detta bättre då det förkortar laddtiden.</p>
        
            <h3>Ampere (A)</h3>
            <p>Enhet för att mäta strömstyrka. Kanske har du sett på säkring i ett elskåp någon gång 16A? Genom att multiplicera ampere med spänningen får man fram effekten. Exempelvis vid 16A säkring och 230 Volt blir effekten 3 680 W (3,7 kW). Vid tre faser på vardera 16A blir istället effekten 11 kW.</p>
        
            
        </Layout>
    )
}

export default OrdUttryckPage